<template>
  <bg-modal
    class="occupied-tenant-modal"
    :value="isOpen"
    :body-scroll="false"
    @close="closeModal"
  >
    <bg-list-item class="mb-16">
      <bg-text size="heading-5">
        {{ contract.tenant_name }}
      </bg-text>
      <template #right-content>
        <bg-label-rainbow :color="getBookingStatus(bookingStatus).color">
          {{ getBookingStatus(bookingStatus).text }}
        </bg-label-rainbow>
      </template>
    </bg-list-item>

    <bg-divider class="mb-16" />

    <template v-if="isRelocated">
      <bg-text
        size="label-2"
        class="occupied-tenant-modal__relocated-description"
      >
        {{ relocatedDescription }}
      </bg-text>

      <bg-button-icon
        icon="chevron-right"
        icon-position="right"
        variant="secondary"
        class="occupied-tenant-modal__button-detail-contract"
        block
        @click="handleSeeRelocatedRoomClicked"
      >
        Lihat kamar {{ relocatedRoom }}
      </bg-button-icon>
    </template>

    <template v-else>
      <template v-for="(list, i) in listsData">
        <bg-list-item v-if="list.isShow" class="mb-16" :key="i">
          {{ list.label }}
          <template #right-content> {{ list.value }} </template>
        </bg-list-item>
      </template>

      <bg-button-icon
        icon="chevron-right"
        icon-position="right"
        variant="secondary"
        class="occupied-tenant-modal__button-detail-contract"
        block
        :disabled="isBooked"
        @click="goToDetailContract(contract.contract_id)"
      >
        Lihat detail kontrak
      </bg-button-icon>
    </template>

    <bg-divider class="mb-16" />

    <bg-list-item>
      <bg-text size="heading-5" class="mb-8"> Kamar {{ roomName }} </bg-text>
      <bg-text size="body-4" class="text-muted">
        {{ unitType }}
      </bg-text>
      <template #right-content>
        <bg-button
          v-if="isShowRelocationBtn"
          variant="secondary"
          @click="handleRelocationButtonClicked"
          active
        >
          Pindah Kamar
        </bg-button>
      </template>
    </bg-list-item>
  </bg-modal>
</template>

<script>
import { STATUS_DATA, getBookingStatus } from 'Utils/bookingStatus';
import _includes from 'lodash/includes';

import {
  BgModal,
  BgText,
  BgLabelRainbow,
  BgButton,
  BgButtonIcon,
  BgListItem,
  BgDivider,
} from 'bangul-vue';
import getPeriode from 'Utils/dictionaries/periode';
import { dateFormatterToDisplay } from 'Utils/formatter';
import dayjs from 'dayjs';

export default {
  name: 'OccupiedRoomModal',

  components: {
    BgModal,
    BgText,
    BgLabelRainbow,
    BgButton,
    BgButtonIcon,
    BgListItem,
    BgDivider,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    contract: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    duration() {
      const periode = getPeriode(this.contract.duration_unit);

      return periode.duration
        ? `${periode.duration * this.contract.duration} ${periode.short_text}`
        : '-';
    },
    checkInDate() {
      return this.formatDate(this.contract.start_date);
    },
    endDate() {
      return this.formatDate(this.contract.end_date);
    },
    checkOutDate() {
      const checkoutDate = this.contract.actual_checkout_date;
      return this.formatDate(checkoutDate);
    },
    terminatedDate() {
      const terminatedDate = this.contract.terminated_date;
      return this.formatDate(terminatedDate);
    },
    bookingId() {
      return this.contract.booking_code || '-';
    },
    roomName() {
      return this.contract.room?.room_name;
    },
    unitType() {
      return this.contract.room?.unit_type;
    },
    bookingStatus() {
      return this.contract.booking_status;
    },
    phoneNumber() {
      return this.contract.phone_number || '';
    },
    isBooked() {
      return this.bookingStatus === STATUS_DATA.booked;
    },
    isTerminated() {
      return this.bookingStatus === STATUS_DATA.terminated;
    },
    isRelocated() {
      return this.bookingStatus === STATUS_DATA.relocated;
    },
    relocatedRoom() {
      return this.contract.relocated_to_room || '';
    },
    relocatedDate() {
      if (this.isRelocated) {
        const relocatedDate = dayjs(this.contract.end_date).add(1, 'day');
        return this.formatDate(relocatedDate);
      }

      return '';
    },
    relocatedDescription() {
      return `Tenant pindah ke kamar ${this.relocatedRoom} pada tanggal ${this.relocatedDate}.`;
    },
    isShowRelocationBtn() {
      return !_includes(
        [
          STATUS_DATA.relocated,
          STATUS_DATA.terminated,
          STATUS_DATA.finished,
          STATUS_DATA.cancelled,
        ],
        this.bookingStatus
      );
    },
    listsData() {
      return [
        {
          label: 'Nomor Telepon',
          value: this.phoneNumber,
          isShow: true,
        },
        {
          label: 'Booking ID',
          value: this.bookingId,
          isShow: true,
        },
        {
          label: 'Durasi sewa',
          value: this.duration,
          isShow: true,
        },
        {
          label: 'Check-in date',
          value: this.checkInDate,
          isShow: true,
        },
        {
          label: 'End date',
          value: this.endDate,
          isShow: true,
        },
        {
          label: 'Check-out date',
          value: this.checkOutDate,
          isShow: this.checkOutDate,
        },
        {
          label: 'Termination date',
          value: this.terminatedDate,
          isShow: this.isTerminated,
        },
      ];
    },
  },

  methods: {
    getBookingStatus,
    goToDetailContract(contractId) {
      this.$router.push({
        name: 'room-allotment-contract-detail',
        params: {
          contractId,
        },
      });
    },

    closeModal() {
      this.$emit('update:isOpen', false);
      this.$emit('update:contract', {});
    },

    formatDate(date) {
      if (!date) return '';

      return dateFormatterToDisplay(date, 'DD MMMM YYYY');
    },

    handleRelocationButtonClicked() {
      this.$emit('click-relocation-button', this.contract);
    },

    handleSeeRelocatedRoomClicked() {
      this.$emit('click-see-relocated-room', {
        date: this.relocatedDate,
        phone: this.phoneNumber,
      });
    },
  },
};
</script>

<style lang="scss" src="./OccupiedRoomModal.scss" scoped></style>
